<template>
  <div class="tabs">
    <el-dialog title=""
               :visible.sync="dialogShow"
               width="1160px"
               :before-close="handleClose">
      <div class="center">
        <div class="left">
          <htCnAmap :searchList="mapList"
                    :mapType="mapType"/>
        </div>
        <div class="right">
          <div class="right-top">
            <span style="text-align:left">{{detalisInfo.jdmc}}</span>
            <span>
              <i class="el-icon-location-outline"></i>
              <p>{{detalisInfo.address}}</p>
            </span>
          </div>
          <div class="right-bottom">
            <div class="tabs-header">
              <span class="tabs"
                    :class="{to_active:isShow === 1}"
                    @click="handleTab(1)">附近</span>
              <span class="tabs"
                    :class="{to_active:isShow === 2}"
                    @click="handleTab(2)">公交</span>
              <span class="tabs"
                    :class="{to_active:isShow === 3}"
                    @click="handleTab(3)">地铁</span>
              <span class="tabs"
                    :class="{to_active:isShow === 4}"
                    @click="handleTab(4)">其他</span>
            </div>
            <div class="tabs-body">
              <div v-show="isShow === 1">
                    <div class="tabs-body-item"  v-for="(item,index) in athoerHoterList" :key="item.index" >
                     {{index+1}} {{item.jdmc}}
                  </div>
              </div>
              <div v-show="isShow === 2">
                  <div class="tabs-body-item" v-for="(item,index) in otherList" :key="item.index" >
                     {{index+1}} {{item.name}}
                  </div>
              </div>
              <div v-show="isShow === 3">
                    <div class="tabs-body-item" v-for="(item,index) in otherList" :key="item.index" >
                     {{index+1}} {{item.name}}
                  </div>
              </div>
              <div v-show="isShow === 4">
                <div class="tabs-body-item" v-for="(item,index) in otherList" :key="item.index" >
                     {{index+1}} {{item.name}}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import htCnAmap from '../HtCnComponent/htCnAmap.vue'
export default {
  name: 'HtCnDetails',
  components: {
    htCnAmap,
  },

  props: {
    dialogShow: {
      type: Boolean,
    },
    detalisInfo: {
      type: Object,
    },
    athoerHoterList:{
        type:Array
    }
  },
  watch:{
      dialogShow(){
           this.mapList.list=this.athoerHoterList
      }
  },
  data() {
    return {
      // dialogVisible: false
      tabPosition: 'left',
      mapType: 2,
      isShow: 1,
      poiType: '',
      mapList: {
        list: [],
      },
      otherList:[]
    }
  },
  mounted() {
    this.$nextTick(()=>{
        this.mapList.list=this.athoerHoterList
    })
  },
  methods: {
    handleTab(val) {
      this.isShow = val
      if(val==1){
          this.mapList.list=this.athoerHoterList
          console.log(this.mapList.list,"我是传入的mapList")
      }
      if (val == 2) {
        this.poiType = 'bus'
      } else if (val == 3) {
        this.poiType = 'subway'
      } else if (val == 4) {
        this.poiType = 'other'
      }
      let objParams = {
        curLat: this.detalisInfo.baiduLat,
        curLng: this.detalisInfo.baiduLon,
        poiType: this.poiType,
      }
      if (val == 2 || val == 3 || val == 4) {
            this.getOther(objParams)
      }
    },
    getOther(params) {
      this.$conn
        .getConn('htbook.poiTraffic', { jsfile: false })(params)
        .then((res) => {
            this.otherList=res.data
            this.mapList.list=res.data
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.tips,
          })
          this.htLoading = false
        })
    },
    handleClose() {
      this.$emit('update:dialogShow', false)
    },
  },
}
</script>

<style lang="scss" scoped="scoped">
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}

.tabs {
  width: 80px;
  display: inline-block;
  line-height: 40px;
  background-color: #f5f5f5;
  .to_active {
    color: #fff;
    font-weight: 600;
    background-color: #327bdc;
  }
}
.center {
  display: flex;
  width: 1160px;
  .left {
    width: 760px;
    height: 600px;
  }
  .right {
    width: 400px;
    height: 600px;
    box-sizing: border-box;
    padding: 64px 30px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .right-top {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span:nth-child(1) {
        font-size: 24px;
        color: #000;
        font-weight: bold;
      }
      span:nth-child(2) {
        display: flex;
        align-items: center;
      }
    }
    .right-bottom {
      width: 340px;
      .tabs-body{
          .tabs-body-item{
              line-height: normal;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-left: 10px;
              margin-top: 5px;
          }
      }
    }
  }
}
</style>
