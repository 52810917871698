<template>
  <el-dialog :fullscreen="true" :visible="value" @close="close">
    <div class="ht-img-pop flex-c-jc">
      <div class="flex">
        <div class="ht-left flex-ac flex-1">
          <el-button type="primary" @click="previousSlide" icon="el-icon-arrow-left" circle></el-button>
          <el-image class="big-img" :src="bigImgUrl" lazy></el-image>
          <el-button type="primary" @click="nextSlide" icon="el-icon-arrow-right" circle></el-button>
        </div>
        <div class="ht-right">
          <div class="title">
            <span>{{ htDetails.jdmc }}</span>
          </div>
          <div class="detail">
            （{{ htDetails.cityIdName }}<span v-if="!!htDetails.businessZonename">-{{ htDetails.businessZonename
            }}</span>）
            <span>{{ htDetails.address }}</span>
          </div>
          <div ng-show="featuresArray.length > 0" class="title">
            <span>酒店设施</span> &nbsp;
          </div>
          <div class="flex-w" ng-show="featuresArray.length > 0">
            <div class="f-tag" v-for="(item, i) in featuresArray" :key="i + item">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-tabs v-model="selectIdx" @tab-click="handleClick">
          <el-tab-pane v-for="(item, idx) in imgArray" :key="item.title + idx" :label="item.title + `(${item.list.length})`" :name="idx + ''">
          </el-tab-pane>
        </el-tabs>
        <ul class="h-imgs" style="margin-top: 20px;">
          <li v-for="(img, imgIndex) in imgs" :key="imgIndex + '_img_key'"
            :class="{ 'h-imgs-single': true, 'h-imgs-single-selected': imgIndex == imgIdx }" @click="imgClick(imgIndex)">
            <img class="small-img" :src="img" />
          </li>
        </ul>
      </div>
      <div class="float-btn" @click="close()">
        <i class="el-icon-error"></i>
        <!-- <el-button type="primary"  @click="nextSlide" icon="el-icon-error" circle></el-button> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'HtImgPop',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    imgDefaultIdx: {
      type: Number,
      default: 0
    },
    imgArray: {
      type: Array,
      default: () => {
        return []
      }
    },
    htDetails: {
      type: Object,
      default: () => {
        return {}
      }
    },

  },
  data() {
    return {
      selectIdx: '0',
      bigImgUrl: '',
      imgs: [], // 展示的图片数组
      imgIdx: 0, // 展示大图的idx
      featuresArray: [], // 设施
    };
  },
  watch: {
    htDetails(n) {
      this.featuresArray = (n.features || '').split(',');
    },
    imgArray(n) {
      if (!n || !n.length) return;
      this.imgs = n[0].list;
      this.bigImgUrl = this.imgs[0];
    },
    imgDefaultIdx(n) {
      this.selectIdx = '0';
      this.imgs = this.imgArray[0].list;
      this.imgClick(n);
    }
  },
  mounted() {

  },
  methods: {
    handleClick() {
      let item = this.imgArray[this.selectIdx];
      this.imgs = item.list;
      this.bigImgUrl = item.list[0];
      this.imgIdx = 0; // 默认选择第一张图片
      this.scrollToCenter(0);
    },
    // 图片点击  
    imgClick(i) {
      if (this.imgIdx == i) return;
      this.imgIdx = i;
      this.bigImgUrl = this.imgs[i];
      this.scrollToCenter(i);
    },
    // 上一张
    previousSlide() {
      let i = this.imgIdx;
      let idx = (i - 1) <= 0 ? 0 : (i - 1);
      this.imgClick(idx);
    },
    // 下一张
    nextSlide() {
      let i = this.imgIdx;
      let idx = (i + 1) >= (this.imgs.length - 1) ? (this.imgs.length - 1) : (i + 1);
      this.imgClick(idx);
    },
    close() {
      this.$emit('input', false);
    },
    // 滚动某个元素到中心
    scrollToCenter(i) {
      this.$nextTick(() => {
        const scrollDom = document.querySelector('.h-imgs')
        const targetDom = scrollDom.children[i];
        this.setScrollToCenter(scrollDom, targetDom, 'x')
      })
    },
    /**
      *点击元素自动滚动到水平或垂直中间位置
      * @param {HTMLElement} scrollDom - 滚动的元素
      * @param {HTMLElement} targetDom - 点击的元素
      * @param {string} [type='x'] - x表示水平，y表示垂直,默认为水平
    */
    setScrollToCenter(scrollDom, targetDom, type = 'x') {
      if (!scrollDom || !targetDom) return false
      //如果是浏览器body的滚动条
      if ([window, document, document.documentElement].includes(scrollDom)) { scrollDom = document.documentElement }
      const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = targetDom
      const { clientWidth, clientHeight } = scrollDom
      const targetDistance = type == 'x' ? offsetLeft : offsetTop
      const scrollClient = type == 'x' ? clientWidth : clientHeight
      const targetOffset = type == 'x' ? offsetWidth : offsetHeight
      const val = targetDistance - scrollClient / 2 + targetOffset / 2
      // x时，相当于:
      // targetDom.offsetLeft - scrollDom.clientWidth / 2 + targetDom.offsetWidth / 2
      // y时，相当于:
      // targetDom.offsetTop - scrollDom.clientHeight / 2 + targetDom.offsetHeight / 2
      const config = { behavior: 'smooth' }
      type === 'x' ? config.left = val : config.top = val
      scrollDom.scrollTo(config)
    }
  },
};
</script>

<style lang="scss" scoped>
.ht-img-pop {
  background-color: black;
  padding: 80px;
  width: 100%;
  height: 100%;

  .float-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 40px;
    color: #fff;
  }
  .ht-left {
    margin-right: 20px;
    height: 65vh;

    .big-img {
      flex: 1;
      max-width: 100%;
      max-height: 100%;
      object-position: center center;
      object-fit: contain;
      width: auto;
      height: auto !important;
      margin: 0 15px;
    }
  }

  .ht-right {
    width: 35%;
    text-align: left;

    .title {
      font-weight: 600;
      font-size: 25px;
      color: #fff;
      margin-bottom: 20px;
    }

    .detail {
      font-size: 16px;
      color: #fff;
      margin-bottom: 40px;
    }


    .f-tag {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 4px;
      padding: 6px 8px;
      margin: 0 8px 8px 0;

      span {
        color: #111;
      }
    }

  }

  .bottom {
    margin-top: 20px;


    .h-imgs {
      align-items: center;
      overflow-x: auto;
      margin: 0 20px;
      text-align: left;
      overflow: auto;
      white-space: nowrap;
      padding-bottom: 12px;
      scroll-behavior: smooth;
    }


    .h-imgs::-webkit-scrollbar {
      background: linear-gradient(180deg, #000 2px, hsla(0, 0%, 100%, .2) 0 8px, #000 8px 10px);
      height: 10px;
    }

    .h-imgs::-webkit-scrollbar-thumb {
      background-color: #dadfe6;
    }


    .h-imgs-single {
      display: inline-block;
      margin-left: 4px;
      overflow: hidden;
      border: 2px solid transparent;
      cursor: pointer;
    }

    .h-imgs-single-selected {
      border: 2px solid #2681ff;
    }

    .small-img {
      width: 120px;
      height: 80px;
    }
  }


  .flex-w {
    display: flex;
    flex-wrap: wrap;
  }

  .flex {
    display: flex;
  }

  .flex-cc {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-ac {
    display: flex;
    align-items: center;
  }


  .flex-c-jc {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}

/deep/.el-dialog__header {
  display: none;
}

/deep/ .el-dialog__body {
  padding: 0;
  height: 100%;
}

/deep/ .el-tabs__item {
  color: #fff;
}

/deep/ .el-tabs__item.is-active {
  color: #409EFF;
}
</style>