
// vue 全局混入
import ccsqdMixin from './models/ccsqdMixin.js'
import commonMixin from './models/commonMixin'
var mixins = {
	// 出差申请单全局混入
	ccsqdMixin:ccsqdMixin,
	commonMixin:commonMixin,
	
};




export default mixins
